
import { defineComponent, computed, onMounted, reactive, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
export default defineComponent({
  setup () {
    const store = useStore()
    const isCollapse = computed(() => store.getters.getIsCollapse)

    const currentMenu = computed(() => store.getters.getCurrentMenu)

    const router = useRouter()
    const toView = (url: string, redirect: string) => {
      if (redirect) {
        router.push({ path: redirect })
      } else {
        router.push({ path: url })
      }
    }
    const switchCollapse = () => {
      store.commit('switchCollapse', !isCollapse.value)
    }
    onMounted(() => {
    })
    const state = {
      title: '绩效系统',
      logoUrl: require('@/assets/logo.png')
    }
    const titleImg = ref()
    watch(() => isCollapse, (newVal, oldVal) => {
    })
    const states: any = reactive({
      routersList: computed(() => store.getters.getRouters)
    })

    return {
      state,
      states,
      isCollapse,
      currentMenu,
      titleImg,
      toView,
      switchCollapse
    }
  }
})
