import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Top = _resolveComponent("Top")!
  const _component_Left = _resolveComponent("Left")!
  const _component_Tags = _resolveComponent("Tags")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_backtop = _resolveComponent("el-backtop")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Top),
    _createVNode(_component_Left),
    _createElementVNode("div", {
      class: _normalizeClass(["content-box", {'content-collapse':_ctx.isCollapse}])
    }, [
      _createVNode(_component_Tags),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_view, null, {
          default: _withCtx(({ Component }) => [
            (_openBlock(), _createBlock(_KeepAlive, null, [
              (_ctx.route.meta.keepAlive)
                ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                    key: _ctx.route.fullPath
                  }))
                : _createCommentVNode("", true)
            ], 1024)),
            (!_ctx.route.meta.keepAlive)
              ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                  key: _ctx.route.fullPath
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_el_backtop, { target: ".content" })
      ])
    ], 2)
  ]))
}