/*
 * @Descripttion:
 * @version:
 * @Author: wenboliu
 * @Date: 2024-03-12 18:02:00
 * @LastEditors: wenboliu
 * @LastEditTime: 2024-07-13 14:57:11
 */
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '@/store/index'

// const routes: Array<RouteRecordRaw> = store.getters.getPermission

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'index',
    meta: {
      title: '首页'
    },
    component: () => import(/* webpackChunkName: "index" */ '@/components/nav/index.vue')
  },
  {
    path: '/403',
    meta: {
      title: '无权限'
    },
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/403.vue')
  },
  {
    path: '/404',
    meta: {
      title: '未找到'
    },
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404.vue')
  },
  {
    path: '/500',
    meta: {
      title: '服务错误'
    },
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/500.vue')
  }]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.afterEach((to: any, from: any, next: any) => {
  // 添加网站标题
  if (to.meta.title && typeof (to.meta.title) === 'string') {
    if (to.path === '/') {
      document.title = '绩效系统'
    } else {
      document.title = to.meta.title + '-英奢集团'
    }
  }
  // if(to.path === '/schemeEdit'){
  //   const currentIndex = (to.matched|| []).findIndex((profile:any) => profile.path === to.path)
  //   if(to.query.id){
  //     to.matched[currentIndex].meta.title = '方案编辑'
  //   }else{
  //     to.matched[currentIndex].meta.title = '方案新增'
  //   }
  // }
  // 顶部面包屑
  const routerList = to.matched
  store.commit('SET_CRUMBLIST', routerList)
  // 当前active导航菜单
  console.log(to.name, from.name, 'allto')
  if (to.name !== 'payslipManage' && to.name !== 'payslipEdit' && to.name !== 'payslipAdd') {
    sessionStorage.setItem('payslipPwd', '')
  }
  if (routerList.length >= 2) {
    const parent = routerList[routerList.length - 2].children
    parent.forEach((item:any) => {
      if (item.name === to.name) {
        if (!item.hidden) {
          store.commit('SET_CURRENT_MENU', to.name)
        } else {
          store.commit('SET_CURRENT_MENU', from.name)
        }
      }
    })
  } else {
    store.commit('SET_CURRENT_MENU', to.name)
  }
})

export default router
