/*
 * @Descripttion: 
 * @version: 
 * @Author: wenboliu
 * @Date: 2024-03-12 18:02:00
 * @LastEditors: wenboliu
 * @LastEditTime: 2024-07-18 14:13:40
 */
import { get, post } from './http'

const API: any = {
  getUserInfo: '/permission/user/currentUser', // 获取用户信息
  getDeptUserInfo: '/permission/feign/departments', // 获取用户信息
  updateUserInfo: '/design/member/update', // 获取用户信息
  getMenus: '/permission/menu/menus', // 获取所有菜单
  getPermission: '/permission/menu/chlidren', // 获取所有按钮
  getRoles: '/permission/menu/roles', // 获取角色信息
  findListByType: '/crm/dictData/findListByType' // 根据类型查询字典列表
  // getUserRoleFlag: '/crm/dictData/findListByType'//
}

export const getUserInfoApi = () => get(API.getUserInfo, '', '')
export const updateUserInfoApi = (data: any) => post(API.updateUserInfo, data, '')

export const getMenusApi = (data: any) => get(API.getMenus, data, '')
export const getPermissionApi = (data: any) => get(API.getPermission, data, '')
export const getRolesApi = (data: any) => get(API.getRoles, data, '')
export const findListByTypeApi = (data: any) => get(API.findListByType, data, '')
export const getDeptUserInfoApi = () => get(API.getDeptUserInfo, '', '')

// export const getUserRoleFlagApi = (data: any) => get(API.getUserRoleFlag, data, '')
