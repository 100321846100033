/*
 * @Descripttion:
 * @version:
 * @Author: wenboliu
 * @Date: 2024-03-22 11:19:05
 * @LastEditors: guomengxia
 * @LastEditTime: 2024-06-30 14:46:42
 */
import { App, DirectiveBinding } from 'vue'

export default (app: App<Element>) => {
  app.directive('btnAntiShake', {
    mounted (el: HTMLElement, binding: DirectiveBinding) {
      let timer: any
      el.addEventListener('click', () => {
        const firstClick = !timer
        if (firstClick) {
          binding.value()
        }
        if (timer) {
          clearTimeout(timer)
        }
        timer = setTimeout(() => {
          timer = null
          if (!firstClick) {
            binding.value()
          }
        }, 1000)
      })
    }
  })
}
