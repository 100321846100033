import { ref } from 'vue'
import axios from 'axios'
import store from '@/store'
export function getCookie (name: string) {
  const strcookie = document.cookie // 获取cookie字符串
  const arrcookie = strcookie.split('; ') // 分割
  // 遍历匹配
  for (let i = 0; i < arrcookie.length; i++) {
    const arr = arrcookie[i].split('=')
    if (arr[0] === name) {
      return arr[1]
    }
  }
  return ''
}
// 将数字格式化为金额格式
export const formatMoney = (number:any, decimals = 0, decPoint = '.', thousandsSep = ',') => {
  number = (number + '').replace(/[^0-9+-Ee.]/g, '')
  const n = !isFinite(+number) ? 0 : +number
  const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
  const sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep
  const dec = (typeof decPoint === 'undefined') ? '.' : decPoint
  const s = ref()
  const toFixedFix = function (n:any, prec:any) {
    const k = Math.pow(10, prec)
    return '' + Math.ceil(n * k) / k
  }
  s.value = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
  const re = /(-?\d+)(\d{3})/
  while (re.test(s.value[0])) {
    s.value[0] = s.value[0].replace(re, '$1' + sep + '$2')
  }
  if ((s.value[1] || '').length < prec) {
    s.value[1] = s.value[1] || ''
    s.value[1] += new Array(prec - s.value[1].length + 1).join('0')
  }

  return s.value.join(dec)
}
// 当天日期
export const todayDate = () => {
  const todayDateValue = ref('')
  const appendZero = (obj: any) => {
    if (obj < 10) {
      return '0' + obj
    } else {
      return obj
    }
  }
  const year = new Date().getFullYear()
  const month = appendZero(new Date().getMonth() + 1)
  const day = appendZero(new Date().getDate())
  const week = new Date().getDay()
  const data = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
  // 修改数据date
  todayDateValue.value =
    year + '年' + month + '月' + day + '日' + ' ' + data[week]
  return todayDateValue
}

export function getMonth (num:any) {
  if (num <= 0) return
  const today = ref()
  const year = ref()
  const month = ref()
  const numRemainder = ref()
  const needMonthNext = ref()
  const needMonth = ref()
  today.value = new Date()
  year.value = today.value.getFullYear() // 当前年份
  let needYear = year.value - Math.floor(num / 12) // 获取到想要查询月所在的年份
  // let needYearNext = needYear // 获取到想要查询月下一个月所在的年份
  month.value = today.value.getMonth() + 1 // 获取到当前月

  // 获取num 的余数  如果余数大于等于当前月份  则年份还要减1
  numRemainder.value = num % 12
  if (numRemainder.value >= month.value) {
    needYear--
    month.value += 12
  }

  // 获取到所需要的月份和所需要的月份的下一个月
  needMonth.value = month.value - numRemainder.value
  needMonthNext.value = needMonth.value + 1

  // 如果所需要月份的下一个月大于12 则需要调到下一年的一月
  if (needMonthNext.value > 12) {
    needMonthNext.value = 1
  }
  if (needMonth.value === 0) needMonth.value = 12
  // 所需月份小于10 则前面补0
  if (needMonth.value < 10) needMonth.value = '0' + needMonth.value
  if (needMonthNext.value < 10) needMonthNext.value = '0' + needMonthNext.value
  // console.log()
  const datatime = new Date(needYear, needMonth.value, 0).getDate()
  // console.log(datatime, '当月最后一天')
  return [`${needYear}-${needMonth.value}-01`, `${needYear}-${needMonth.value}-${datatime}`]
}
function doHandleMonth (month:any) {
  let m = month
  if (month.toString().length === 1) {
    m = '0' + month
  }
  return m
}

// 获取当天日期
export function getDay (day:any) {
  const today = new Date()
  const targetdayMilliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day
  today.setTime(targetdayMilliseconds) // 注意，这行是关键代码
  const tYear = today.getFullYear()
  let tMonth = today.getMonth()
  let tDate = today.getDate()
  tMonth = doHandleMonth(tMonth + 1)
  tDate = doHandleMonth(tDate)
  return tYear + '-' + tMonth + '-' + tDate
}

// 获取本月数据
export function getCurrentMonth () {
  const today = ref()
  const year = ref()
  const month = ref()
  const datatime = ref()
  today.value = new Date()
  year.value = today.value.getFullYear() // 当前年份
  month.value = today.value.getMonth() + 1 // 获取到当前月
  const startTimeOne = ref()
  const endTimeOne = ref()
  const time = ref<any>()
  datatime.value = new Date(year.value, month.value, 0).getDate()
  if (month.value < 10) {
    startTimeOne.value = `${year.value}-0${month.value}-01`
    endTimeOne.value = `${year.value}-0${month.value}-${datatime.value}`
  } else {
    startTimeOne.value = `${year.value}-${month.value}-01`
    endTimeOne.value = `${year.value}-${month.value}-${datatime.value}`
  }
  time.value = [startTimeOne.value, endTimeOne.value]
  return time.value
}
// 上传图片的请求头
export function getCookieToken (name:any) {
  const strcookie = document.cookie // 获取cookie字符串
  const arrcookie = strcookie.split('; ') // 分割
  // 遍历匹配
  for (let i = 0; i < arrcookie.length; i++) {
    const arr = arrcookie[i].split('=')
    if (arr[0] === name) {
      return arr[1]
    }
  }
  return ''
}
// 导出
export function exportListData (interfaceApi:any, params:any, fileName:any) {
  interfaceApi({ ...params }).then((res:any) => {
    const url = window.URL.createObjectURL(
      new Blob([res.data], { type: 'application/vnd.ms-excel' })
    )
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = url
    a.setAttribute('download', fileName)
    document.body.appendChild(a)
    a.click() // 执行下载
    window.URL.revokeObjectURL(a.href)
    document.body.removeChild(a)
  })
}
export const hasPermission = (permission:any)=>{
  const myPermissions = store.getters.getPermission || []
  return myPermissions.indexOf(permission) > -1
}
