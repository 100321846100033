
import { defineComponent, computed, onMounted, watch, ref } from 'vue'
import { useRoute } from 'vue-router'
import Top from '@/components/nav/navTop.vue'
import Left from '@/components/nav/navLeft.vue'
import Tags from '@/components/nav/navTags.vue'
import { useStore } from 'vuex'
export default defineComponent({
  name: 'index',
  setup () {
    const store = useStore()
    const isCollapse = computed(() => store.getters.getIsCollapse)
    const screenWidth = ref(0)
    const route = useRoute()
    onMounted(() => {
      window.addEventListener('resize', function () {
        screenWidth.value = document.body.offsetWidth
      })
    })

    watch(screenWidth, (n, o) => {
      if (n <= 1200) {
        store.commit('switchCollapse', true)
      } else {
        store.commit('switchCollapse', false)
      }
    })

    return { isCollapse, route }
  },

  components: { Top, Left , Tags}
})
