/*
 * @Descripttion:
 * @version:
 * @Author: guomengxia
 * @Date: 2023-02-28 15:51:53
 * @LastEditors: guomengxia
 * @LastEditTime: 2024-07-06 09:18:41
 */
import { createStore } from 'vuex'
// import actions from './actions'
// const userList = sessionStorage.getItem('userList')
export default createStore({
  state: {
    /* 所有路由 */
    routersList: null,
    /* 所有权限 */
    permissionList: null,
    /* 当前active导航菜单 */
    currentMenu: '',
    /* 导航菜单是否折叠 */
    isCollapse: false,
    /* 面包屑导航列表 */
    crumbList: [],
    // 角色信息
    rolesList: [],
    // 用户信息
    userList: [],
    currentRoleFlag: null// 当前角色标题 1是运营人员，2是主管及经理以上
  },
  getters: {
    getRouters: (state: any) => state.routersList,
    getPermission: (state: any) => state.permissionList,
    getCurrentMenu: (state: any) => state.currentMenu,
    getIsCollapse: (state: any) => state.isCollapse,
    getCrumbList: (state: any) => state.crumbList,
    getRolesList: (state: any) => state.rolesList,
    getUserList: (state: any) => state.userList,
    currentRoleFlag: (state: any) => state.currentRoleFlag
  },
  mutations: {
    /* 所有路由 */
    SET_ROUTERS (state: any, routes: Array<any>) {
      state.routersList = routes
    },
    /* 所有权限 */
    SET_PERMISSION (state: any, routes: Array<any>) {
      state.permissionList = routes
      // 当数据变更时，同步到 sessionStorage
      sessionStorage.setItem('permissionList', JSON.stringify(routes))
    },
    /* 当前active导航菜单 */
    SET_CURRENT_MENU (state: any, currentMenu: string) {
      state.currentMenu = currentMenu
    },
    /* 导航菜单是否折叠 */
    switchCollapse (state: any, isCollapse: boolean) {
      state.isCollapse = isCollapse
    },
    /* 面包屑导航列表 */
    SET_CRUMBLIST (state: any, list: Array<any>) {
      state.crumbList = list
    },
    /* 角色信息 */
    SET_ROLES (state: any, routes: Array<any>) {
      state.rolesList = routes
    },
    SET_USERS (state: any, routes:Array<string>) {
      state.userList = routes
      // sessionStorage.setItem('userList', routes)
    },
    SET_ROLE_FLAG (state: any, routes:Array<string>) {
      state.currentRoleFlag = routes
    }
  },
  actions: {
  },
  // 在 store 创建时，从 sessionStorage 加载数据
  plugins: [
    store => {
      store.subscribe((mutation, state) => {
        // 监听 state 的变化，并同步到 sessionStorage
        // 注意：这里只同步我们关心的字段，避免不必要的同步
        if (mutation.type === 'SET_PERMISSION') {
          sessionStorage.setItem('permissionList', JSON.stringify(state.permissionList))
        }
      })

      // 在 store 创建时从 sessionStorage 恢复数据
      const savedData = sessionStorage.getItem('permissionList')
      if (savedData) {
        store.commit('SET_PERMISSION', JSON.parse(savedData))
      }
    }
  ],
  modules: {
  }
})
