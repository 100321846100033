<!--
 * @Descripttion:
 * @version:
 * @Author: wenboliu
 * @Date: 2024-03-12 18:02:00
 * @LastEditors: wenboliu
 * @LastEditTime: 2024-07-08 10:01:03
-->
<template>
  <router-view/>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;
  }
}
.el-overlay{
  left: 208px!important;
  top: 96px!important;
}
.el-overlay-dialog{
  left: 208px!important;
  top: 96px!important;
}
</style>
