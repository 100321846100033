/*
 * @Descripttion:
 * @version:
 * @Author: wenboliu
 * @Date: 2024-03-12 18:02:00
 * @LastEditors: wenboliu
 * @LastEditTime: 2024-07-08 14:34:41
 */
import Vue, { createApp, Directive } from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import * as ElIcons from '@element-plus/icons-vue'
import 'element-plus/theme-chalk/index.css'
import './menu' // 判断登陆人是否有权限
import './assets/iconfont/iconfont.css'
import btnAntiShake from './utils/btnAntiShake'
import * as directives from '@/directives'
import domtoimage from 'dom-to-image'
import html2canvas from 'html2canvas'
import './global.less' // global style
import 'xe-utils'
import VXETable from 'vxe-table'
import 'default-passive-events'
import './assets/styles/common.scss'
import './assets/css/icon.css'
import './assets/css/Icon-css/iconfont.css'
const app = createApp(App)
for (const name in ElIcons) {
  app.component(name, (ElIcons as never)[name])
}
// console.log(directives, 'directives') // 打印发现是导出的自定义指令名，permission
Object.keys(directives).forEach(key => { // Object.keys() 返回一个数组，值是所有可遍历属性的key名
  app.directive(key, (directives as { [key: string ]: Directive })[key]) // key是自定义指令名字；后面应该是自定义指令的值，值类型是string
})

app.use(store).use(router).use(btnAntiShake).use(VXETable).use(ElementPlus, { locale: zhCn }).mount('#app')
