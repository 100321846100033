
import { defineComponent, computed, ref, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { ElMessage, ElMessageBox } from 'element-plus'

export default defineComponent({
  setup () {
    const router = useRouter()
    const store = useStore()
    const loginOut = () => {
      ElMessageBox.confirm('你确定要退出登录吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        document.cookie = 'token=123;' + 'expires=Thu, 01 Jan 1970 00:00:00 GMT' + ';path=/;domain=' + process.env.VUE_APP_API_TOKEN
        window.location.href = `${process.env.VUE_APP_API_BASE_LOGIN}`
      }).catch(() => {
        ElMessage.info({
          message: '已取消'
        })
      })
    }
    const backHome = () => {
      window.location.href = process.env.VUE_APP_API_BASE_CONSOLE || ''
    }
    // 获取 DOM 元素
    const domTopRef: any = ref(null)
    const users = computed(() => store.state.userList)
    const userInfo = JSON.parse(JSON.stringify(users.value))
    const isCollapse = computed(() => store.getters.getIsCollapse)
    const switchCollapse = () => {
      store.commit('switchCollapse', !isCollapse.value)
    }

    const user = reactive({
      name: userInfo.userName,
      photoUrl: 'https://img0.baidu.com/it/u=2924490029,2419431244&fm=253&fmt=auto&app=138&f=JPEG?w=200&h=200'
    })

    // 获取按钮权限
    // getUserInfoApi().then((res: any) => {
    //   if (res.data.code !== 200) return ElMessage.error(res.data.message)
    //   user.name = res.data.data.userName
    // })

    return {
      user,
      backHome,
      loginOut,
      domTopRef,
      isCollapse,
      switchCollapse
    }
  }
})
